import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { refreshTokenAction } from '../redux_files/actions/users_actions';
import { pageAction } from '../redux_files/actions/page_actions';

const Refresh = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(state => state?.usersReducer?.loggedInUserDetails?.accessToken);

  useEffect(() => {
    document.title = 'Shivay Durga Foods';
    dispatch(pageAction('Shivay Durga Foods'));
  }, []);

  useEffect(() => {
    dispatch(refreshTokenAction(token));
    navigate('/', { replace: true });
  })
  return (
    <div className='container'></div>
  );
};

export default Refresh;
