import React from 'react';
import { Menu } from './menu';

function App() {
  return (
    <div className='App '>
      <Menu />
    </div>
  );
}

export default App;
