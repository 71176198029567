import React from 'react';
import PropTypes from 'prop-types';
import loaderGif from '../assets/loader.gif';

const Loader = ({showOrHideLoader}) => {
    return (
        <>
            {showOrHideLoader && (<img src={loaderGif} alt='Shivay Durga' width='100%' />)}
        </>
    );
}
Loader.defaultProps = {
    showOrHideLoader: false
};

Loader.propTypes = {
    showOrHideLoader: PropTypes.bool,
};
  
export default Loader;