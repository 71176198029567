import * as types from '../types';

const initialState = {
    pageTitle: ''
}

const pageReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: action.payload
            }
        default: 
            return state;
    }
}
export default pageReducer;