import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FoodMenu from '../components/food_menu';
import FoodProperty from '../components/food_property';
import { pageAction } from '../redux_files/actions/page_actions';
import { getUserName } from '../utils/common';
// import { checkUserIsAuthAction } from '../redux_files/actions/users_actions';

// import { BarCodeScanner } from './barcode_scanner';

const Home = () => {
  const dispatch = useDispatch();
  const loggedInUserDetails = useSelector(state => state?.usersReducer?.loggedInUserDetails);
  const userName = getUserName(loggedInUserDetails);
  useEffect(() => {
    document.title = 'Shivay Durga Foods';
    dispatch(pageAction('Shivay Durga Foods'));
  }, [document.title]);
  // useEffect(() => {
  //   if(userName) dispatch(checkUserIsAuthAction());
  // },[]);
  return (
    <div className='container'>
      {/* <BarCodeScanner /> */}
      <div className='text-center auto-margin'>
        <Link to={userName ? '/cart' : '/login'}><div className='primary-button'>Order Now</div></Link>
      </div>
      <FoodMenu />
      <FoodProperty />  
    </div>
  );
};

export default Home;
