import * as types from "../adminTypes";

export const saveUserAction = (userData) =>  {
    return {
        type: types.SAVE_USER,
        payload: userData,
    }
}
export const saveUserResponseAction = (responseData) =>  {
    return {
        type: types.SAVE_USER_RESPONSE,
        payload: responseData?.data ?? {}
    }
}
export const getUserAction = () =>  {
    return {
        type: types.GET_USER
    }
}
export const getUsersResponseAction = (responseData) =>  {
    return {
        type: types.GET_USER_RESPONSE,
        payload: responseData?.data ?? {}
    }
}
export const setCurrentUser = (userData) => {
    return {
        type: types.SELECTED_USER,
        payload: userData
    }
}
export const updateUserAction = (userData) => {
    return {
        type: types.UPDATE_USER,
        payload: userData
    }
}
export const updateUsersResponseAction = (responseData) =>  {
    return {
        type: types.UPDATE_USER_RESPONSE,
        payload: responseData?.data ?? {}
    }
}
