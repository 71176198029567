import React, { useState, useEffect } from 'react';
import {
  Link,
  useNavigate
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import showHideLoaderAction from '../redux_files/actions/loader_action';
import sendOTPAction, { sendOTPResponseAction, verifyOTPAction, verifyOTPResponseAction, registerUserAction, registerUserResponseAction } from '../redux_files/actions/users_actions';
import { pageAction } from '../redux_files/actions/page_actions';
import getErrorMessages, { ERROR_201, SUCCESS_200, ERROR_204 } from '../utils/user';

const Register = () => {
	const formData = {hno: "", name: "", street: "", city: "Panipat", state: "Haryana", pincode: "", workOrHome: "", mobile: "", email: "", tnc: ""};
	const [responseBody, setResponseBody] = useState(formData);
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showCompleteForm, setShowCompleteForm] = useState(false);
  const [showVerifyForm, setShowVerifyForm] = useState(false);
  const sendOTPResponse = useSelector(state => state?.usersReducer?.sendOTPResponse?.code);
  const verifyOTPResponse = useSelector(state => state?.usersReducer?.verifyOTPResponse?.code);
  const registerUserResponse = useSelector(state => state?.usersReducer?.registerUserResponse?.code);
  
	const inputChangeHandler = (event) => {
		const {name, value} = event.target;
		setResponseBody({...responseBody, [name]: value});
	}
  useEffect(() => {
    document.title = 'Register';
    dispatch(pageAction('Register'));
    dispatch(sendOTPResponseAction({}));
    dispatch(verifyOTPResponseAction({}));
    dispatch(registerUserResponseAction({}));
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    const message = getErrorMessages(sendOTPResponse);
    if(sendOTPResponse === SUCCESS_200){
      setShowVerifyForm(true);
      setShowError(false);
      setErrorMessage(undefined);
    } else if(sendOTPResponse === ERROR_201){
      setShowVerifyForm(false);
      setErrorMessage(message);
      setShowError(true);
    } else {
      setShowVerifyForm(false);
      setErrorMessage(undefined);
      setShowError(false);
    }
  }, [sendOTPResponse]);
  useEffect(() => {
    if(verifyOTPResponse === SUCCESS_200) {
      setShowCompleteForm(true);
      setResponseBody(formData);
    } else  if(verifyOTPResponse === ERROR_204) {
      setShowError(true);
      setShowCompleteForm(false);
      setResponseBody(formData);
    } else {
      setShowError(false);
      setShowCompleteForm(false);
      setResponseBody(formData);
    }
  }, [verifyOTPResponse]);
  useEffect(() => {
    if(registerUserResponse === SUCCESS_200){
      setOTP("");
      dispatch(verifyOTPResponseAction({}));
      dispatch(registerUserResponseAction({}));
      alert('User Created! Please Login');
      navigate('/login', { replace: true });
    }
  }, [registerUserResponse]);
  const dispatch = useDispatch();
  const handleLoader = (loaderState) => {
    dispatch(showHideLoaderAction(loaderState));
  }
	const handleUser = (event) => {
		event.preventDefault();
    handleLoader(true);
    const tempData = {...responseBody, mobile};
		dispatch(registerUserAction(tempData));
    handleLoader(false);
	}
  const sendOtpIfuserDoesNotExists = (event) => {
    event.preventDefault();
    handleLoader(true);
    dispatch(sendOTPResponseAction({}));
    dispatch(sendOTPAction(mobile));
    setShowCompleteForm(false);
    handleLoader(false);
  }
  const verifyOTP = (event) => {
    event.preventDefault();
    handleLoader(true);
    dispatch(verifyOTPAction({otp, mobile}));
    handleLoader(false);
  }

  const renderCompleteForm = () => {
    return (
      <form onSubmit={handleUser} method="post">
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='labelDiv'><label className='align-to-center text-size-16'>Mobile<span className='required'>*</span></label></div>
          <input value={mobile} className='col display-flex align-to-center text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter Mobile" name="mobile" required pattern="[0-9]{10}" maxLength="10" title="Please enter at least 10 digits" onChange={(e)=>inputChangeHandler(e)} readOnly />
        </div>
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='labelDiv'><label className='align-to-center text-size-16'>Name<span className='required'>*</span></label></div>
          <input className='col display-flex align-to-center text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter Name" name="username" required pattern=".{3,}" title="Please enter at least 3 characters" onChange={(e)=>inputChangeHandler(e)} />
        </div>
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='labelDiv'><label className='align-to-center text-size-16'>House/Office No.<span className='required'>*</span></label></div>
          <input className='col display-flex align-to-center text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter House/Office Number" name="hno" required pattern="[1-9]\d*" maxLength="5" title="Please enter only digits" onChange={(e)=>inputChangeHandler(e)} />
        </div>
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='labelDiv'><label className='align-to-center text-size-16'>Address<span className='required'>*</span></label></div>
          <input className='col display-flex align-to-center text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter Address" name="street" required pattern=".{3,}" title="Please enter at least 3 characters" onChange={(e)=>inputChangeHandler(e)} />
        </div>
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='labelDiv'>
            <label className='align-to-center text-size-16'>City<span className='required'>*</span></label>
          </div>
          <input value="Panipat" className='col display-flex align-to-center text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter City" name="city" required pattern="[a-zA-Z ]*" title="Please enter at least 3 characters" onChange={(e)=>inputChangeHandler(e)} />
        </div>
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='labelDiv'><label className='align-to-center text-size-16'>State<span className='required'>*</span></label></div>
          <input value="Haryana" className='col display-flex align-to-center text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter State" name="state" required pattern="[a-zA-Z ]*" title="Please enter at least 3 characters" onChange={(e)=>inputChangeHandler(e)} />
        </div>
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='labelDiv'><label className='align-to-center text-size-16'>Pincode<span className='required'>*</span></label></div>
          <input className='col display-flex align-to-center text-size-12 ml-10 pd-10 input-box' value="132103" type="text" placeholder="Enter Pincode" name="pincode" required pattern="[0-9]{6}" maxLength="6" title="Please enter at least 6 digits" onChange={(e)=>inputChangeHandler(e)} />
        </div>
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='labelDiv'><label className='align-to-center text-size-16'>Email</label></div>
          <input className='col display-flex align-to-center text-size-12 ml-10 pd-10 input-box' type="email" placeholder="Enter email" name="email" onChange={(e)=>inputChangeHandler(e)} />
        </div>
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='labelDiv'><label className='align-to-center text-size-16'>Address Type</label></div>
          <select className='col display-flex align-to-center text-size-12 ml-10 pd-10 wd-40' name="workOrHome" id="workOrHome" required onChange={(e)=>inputChangeHandler(e)}>
            <option value="">Select Address Type</option>
            <option value="work">Work</option>
            <option value="home">Home</option>
          </select>
        </div>
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='col wd-100'><input type="checkbox" name="tnc" required onChange={(e)=>inputChangeHandler(e)} /> <span className='required'>*</span>Check here to indicate that you have read and agreed to <Link to="/terms" className='text-primary'>terms and conditions</Link>.</div>
        </div>
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='buttonDiv'>
            <button type="submit" className='primary-button' name='register'>Register</button>
          </div>
          <div className='buttonDiv'>
            <Link to='/login'><button type="button" className='secondary-button'>Cancel</button></Link>
          </div>
        </div>
      </form>
    )
  }

  const renderOTPForm = () => {
    return (
      <form onSubmit={sendOtpIfuserDoesNotExists} method="post">
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='labelDiv'><label className='align-to-center text-size-16'>Mobile<span className='required'>*</span></label></div>
          <input className='col display-flex align-to-center text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter Mobile" name="mobile" required title="Please enter at least 10 digits" onChange={(evt)=>setMobile(evt.target.value)} pattern="[0-9]{10}" maxLength="10" />
        </div>
        {
          showError && (<div className='row wd-100 text-center mb-10 mt-10'><div className='align-to-center text-size-16 text-red'>{errorMessage}</div><div className='row wd-100 text-center mb-10 mt-10'>
          <div className='buttonDiv'>
            <Link to='/login' className='wd-100'><button type="button" className='primary-button' name='login'>Login</button></Link>
          </div>
        </div></div>) 
        }
        <div className='row wd-100 text-center mb-10 mt-20 justify-center'>
          <div className='buttonDiv'>
            <button type="submit" className='primary-button wd-100' name='sendOtp'>Send OTP</button>
          </div>
          <div className='buttonDiv'>
            <Link to='/login'><button type="button" className='secondary-button'>Cancel</button></Link>
          </div>
        </div>
      </form>
    )
  }

  const renderVerifyOTPForm = () => {
    return (
      <form onSubmit={verifyOTP} method="post">
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='labelDiv'>
            <label className='align-to-center text-size-16'>Mobile<span className='required'>*</span></label>
          </div>
          <input className='col display-flex align-to-center text-size-12 ml-10 pd-10 input-box' type="text" title="Please enter at least 10 digits" value={mobile} readOnly pattern="[0-9]{10}" maxLength="10" /><br />
        </div>
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className="text-green align-to-center wd-100">OTP Sent, Valid for 5 minutes.
          </div>
        </div>
       
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='labelDiv'><label className='align-to-center text-size-16'>Enter OTP<span className='required'>*</span></label></div>
          <input className='col display-flex align-to-center text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter OTP" name="otp" required onChange={(evt)=>setOTP(evt.target.value)} pattern="[0-9]{4}" maxLength="4" />
        </div>

        {
          showError && (<div className='row wd-100 text-center mb-10 mt-10'><div className='align-to-center text-size-16 text-red'>{getErrorMessages(verifyOTPResponse)}</div></div>) 
        }
       
        <div className='row wd-100 text-center mb-10 mt-10'>
          <div className='col wd-30 display-flex'>
            <button type="submit" className='primary-button' name='verifyOTP'>Verify OTP</button>
          </div>
          <div className='col wd-30 display-flex'>
            <button type="button" className='primary-button' id='resend' onClick={sendOtpIfuserDoesNotExists}>Resend OTP</button>
          </div>
        </div>
      </form>
    )
  }
  return (
    <div className='container'>
      <div className='inner-container'>
        <div className='align-to-center text-size-16'>Please enter your details</div>
        <div className='outer-box'>
          {showCompleteForm ? renderCompleteForm() : showVerifyForm ? renderVerifyOTPForm() :renderOTPForm()}
        </div>
      </div>	
    </div>
  );
};
export default Register;
