export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_RESPONSE = "SAVE_USER_RESPONSE";
export const GET_USER = "GET_USER";
export const GET_USER_RESPONSE = "GET_USER_RESPONSE";
export const SELECTED_USER = "SELECTED_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_RESPONSE = "UPDATE_USER_RESPONSE";
const adminTypes = {
    SAVE_USER,
    SAVE_USER_RESPONSE,
    GET_USER,
    GET_USER_RESPONSE,
    SELECTED_USER,
    UPDATE_USER,
    UPDATE_USER_RESPONSE
}
export default adminTypes;