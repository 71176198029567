import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import showHideLoaderAction from '../redux_files/actions/loader_action';
import { sendOTPLoginAction, sendOTPLoginResponseAction } from '../redux_files/actions/users_actions';
import { pageAction } from '../redux_files/actions/page_actions';
import { getLoginErrorMessages, SUCCESS_200, ERROR_201, ERROR_204 } from '../utils/user';

const Login = () => {
  const [showOTPBox, setShowOTPBox] = useState(false);
  const [showRegisterButton, setShowRegisterButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendOTPloginUserResponse = useSelector(state => state?.usersReducer?.sendOTPloginUserResponse?.code);
  const sendOTPloginUserResponseMessage = useSelector(state => state?.usersReducer?.sendOTPloginUserResponse?.message);

  useEffect(() => {
    document.title = 'Login';
    dispatch(pageAction('Login'));
    dispatch(sendOTPLoginResponseAction({}));
  }, [document.title]);
  useEffect(() => {
    if(sendOTPloginUserResponse === SUCCESS_200) {
      setErrorMessage("");
      setShowRegisterButton(false);
      setShowOTPBox(true);
    } else if(sendOTPloginUserResponse === ERROR_201) {
      setShowRegisterButton(true);
      setShowOTPBox(false);
      setErrorMessage("");
    } else if(sendOTPloginUserResponse === ERROR_204) {
      setShowRegisterButton(false);
      setShowOTPBox(true);
      setErrorMessage(getLoginErrorMessages(sendOTPloginUserResponse));
    } else {
      setShowOTPBox(false);
      setShowRegisterButton(false);
      setErrorMessage(undefined);
    }
  }, [sendOTPloginUserResponse]);
  useEffect(() => {
    if(sendOTPloginUserResponseMessage === "OTP Verified" && sendOTPloginUserResponse === SUCCESS_200) {
      setErrorMessage("");
      setShowRegisterButton(false);
      navigate('/', { replace: true });
    }
  }, [sendOTPloginUserResponseMessage]);
  const handleLoader = (loaderState) => {
    dispatch(showHideLoaderAction(loaderState));
  }
  const sendOTP = (event) => {
		event.preventDefault();
    handleLoader(true);
    setShowRegisterButton(false);
    setShowOTPBox(true);
    if(mobile) {
      dispatch(sendOTPLoginAction({mobile}));
    }
    handleLoader(false);
	}
  const loginOTP = (event) => {
		event.preventDefault();
    handleLoader(true);
    setShowRegisterButton(false);
    setShowOTPBox(false);
    if(mobile) {
      dispatch(sendOTPLoginAction({otp, mobile}));
    }
    handleLoader(false);
	}
  return (
    <div className='container'>
        <div className='inner-container'>
					<div className='outer-box'>
            <form onSubmit={showOTPBox ? loginOTP : sendOTP} method="post">
              <div className='row wd-100 text-center mb-10 mt-10'>
                <div className='labelDiv'><label className='align-to-center text-size-16'>Mobile<span className='required'>*</span></label></div>
                <input className='col display-flex align-to-center text-size-12 ml-10 pd-10 input-box' type="text" title="Please enter at least 10 digits" value={mobile} onChange={(evt)=>setMobile(evt.target.value)} pattern="[0-9]{10}" maxLength="10" placeholder="Enter Mobile" /><br />
                { errorMessage && <div className='align-to-center text-size-16 text-red'>{errorMessage}</div>}
              </div>
              {
                showOTPBox && (
                  <>
                    {!errorMessage && (<div className='row wd-100 text-center mb-10 mt-10'>
                      <div className="text-green text-bold align-to-center wd-100">OTP Sent, Valid for 5 minutes.</div>
                    </div>)}
                    <div className='row wd-100 text-center mb-10 mt-10'>
                      <div className='labelDiv'><label className='align-to-center text-size-16'>Enter OTP<span className='required'>*</span></label></div>
                      <input className='col display-flex align-to-center text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter OTP" name="otp" required onChange={(evt)=>setOTP(evt.target.value)} pattern="[0-9]{4}" maxLength="4" />
                    </div>
                  </>
                )
              }
              {
                showRegisterButton && (
                  <>
                    <div className='row wd-100 text-center mb-10 mt-10 text-red'>
                      User Does not exists, Please <Link to='/register'>Register</Link> yourself.
                    </div>
                    <div className='row wd-100 text-center mb-10 mt-10 text-red'>
                      <div className='buttonDiv'>
                        <button type="submit" className='primary-button' name='loginOTP'>Login</button>
                      </div>
                      {/* <div className='col wd-25 display-flex'>
                        <button type="button" className='primary-button' id='resend' onClick={sendOTP}>Resend OTP</button>
                      </div> */}
                    </div>
                  </>
                )
              }
              
              <div className='row wd-100 text-center mb-10 mt-10'>
                {!showRegisterButton && !showOTPBox && (
                  <div className='buttonDiv'>
                    <button type="submit" className='primary-button' name='sendOTP'>Send OTP</button>
                  </div>
                 )}
                { showOTPBox && (
                  <>
                    <div className='buttonDiv'>
                      <button type="submit" className='primary-button' name='loginOTP'>Login</button>
                    </div>
                    <div className='buttonDiv'>
                     <button type="button" className='primary-button' id='resend' onClick={sendOTP}>Resend OTP</button>
                    </div>
                  </>) }
              </div>
            </form>
          </div>
        </div>
    </div>
  );
};

export default Login;
