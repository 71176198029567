import * as types from '../types';

const initialState = {
    sendOTPResponse: '',
    verifyOTPResponse: '',
    registerUserResponse: '',
    sendOTPloginUserResponse: '',
    loggedInUserDetails: '',
    userDetails: '',
    userAuthorized: ''
}

const usersReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.SEND_OTP:
            return {
                ...state
            }
        case types.SEND_OTP_RESPONSE:
            return {
                ...state,
                sendOTPResponse: action.payload
            }
        case types.VERIFY_OTP:
            return {
                ...state
            }
        case types.VERIFY_OTP_RESPONSE:
            return {
                ...state,
                verifyOTPResponse: action.payload
            }
        case types.REGISTER_USER:
            return {
                ...state,
            }
        case types.REGISTER_USER_RESPONSE:
            return {
                ...state,
                registerUserResponse: action.payload
            }
        case types.SEND_OTP_LOGIN:
            return {
                ...state
            }
        case types.SEND_OTP_LOGIN_RESPONSE:
            return {
                ...state,
                sendOTPloginUserResponse: action.payload,
            }
        case types.SAVE_LOGGED_IN_USER:
            return {
                ...state,
                loggedInUserDetails: action.payload
            }
        case types.GET_ADDRESS:
            return {
                ...state
            }
        case types.GET_ADDRESS_RESPONSE:
            return {
                ...state,
                userAddress: action.payload
            }
        case types.SET_ADDRESS:
            return {
                ...state
            }
        case types.SET_ADDRESS_RESPONSE:
            return {
                ...state,
                setAddressResponse: action.payload
            }
        case types.SET_DEFAULT_ADDRESS:
            return {
                ...state
            }
        case types.SET_DEFAULT_ADDRESS_RESPONSE:
            return {
                ...state,
                defaultAddressResponse: action.payload
            }
        case types.CHECK_IF_USER_EXISTS:
            return {
                ...state
            }
        case types.GET_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload
            }
        case types.CHECK_USER_IS_AUTHORISED:
            return {
                ...state
            }
        case types.CHECK_USER_IS_AUTHORISED_RESPONSE:
            return {
                ...state
            }
        case types.REFRESH_TOKEN:
            return {
                ...state
            }
        case types.REFRESH_TOKEN_RESPONSE:
            return {
                ...state
            }
        case types.LOGOUT_USER:
            return {};
        default: 
            return state;
    }
}
export default usersReducer;