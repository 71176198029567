import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Steps from './steps';
import tiffinImg from '../assets/tiffin.jpg';
import { getAddressAction, setAddressAction, setDefaultAddressAction } from '../redux_files/actions/users_actions';
import showHideLoaderAction from '../redux_files/actions/loader_action';
import { updateDeliveryAddressAction, paymentSummaryAction } from '../redux_files/actions/product_actions';
import { pageAction } from '../redux_files/actions/page_actions';
import { getSubId } from '../utils/common';
import { SUCCESS_200 } from '../utils/user';
import AddAddress from './addAddress';
import MapContainer from '../components/custom_map';

const DeliveryAddress = () => {
  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(undefined);
  const [currentStep, setCurrentStep] = useState(2);
  const [location, setUserLocation] = useState({});
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [showError, setShowError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setAddressResponse = useSelector(state => state?.usersReducer?.setAddressResponse);
  const userAddress = useSelector(state => state?.usersReducer?.userAddress);
  const updateAddressResponse = useSelector(state => state?.productReducer?.updateAddressResponse);
  const paymentSummary = useSelector(state => state?.productReducer?.paymentSummary);
  const defaultAddressResponse = useSelector(state => state?.usersReducer?.defaultAddressResponse);
  const loggedInUserDetails = useSelector(state => state?.usersReducer?.loggedInUserDetails);
  
  const subId = getSubId(loggedInUserDetails);
  useEffect(() => {
    document.title = 'Select Delivery Address';
    dispatch(pageAction('Select Delivery Address'));
  }, [document.title]);

  useEffect(() => {
    if(userAddress?.code === SUCCESS_200) {
      const addresses = userAddress?.address;
      const addressesArray = [];
      addresses?.map((address) => {
        addressesArray.push({"mainaddress": `${address?.hno}, ${address?.city}, ${address?.state}, ${address?.pincode}`, "defaultAddress": `${address?.defaultAddress}`, "addressId": `${address?.addressId}` })
      })
      setDeliveryAddress(addressesArray);
    }
  }, [userAddress]);
  useEffect(() => {
    dispatch(getAddressAction(subId));
  }, [defaultAddressResponse]);
  const handleLoader = (loaderState) => {
    dispatch(showHideLoaderAction(loaderState));
  }
  useEffect(() => {
    handleLoader(true);
    if(setAddressResponse?.code === SUCCESS_200) {
      dispatch(paymentSummaryAction({...paymentSummary, selectedDeliveryAddress}));
      setCurrentStep(3);
      navigate('/makepayment', { replace: true });
    }
    handleLoader(false);
  }, [setAddressResponse]);
  
  useEffect(() => {
    handleLoader(true);
    if(updateAddressResponse?.code === SUCCESS_200){
      setCurrentStep(2);
    }
    handleLoader(false);
  }, [updateAddressResponse]);
  const confirmDeliveryAddress = (event) => {
      event.preventDefault();
      handleLoader(true);
      console.log("selectedDeliveryAddress", selectedDeliveryAddress);
      if(selectedDeliveryAddress) {
        setShowError(false);
        dispatch(updateDeliveryAddressAction({selectedDeliveryAddress, orderId: paymentSummary?.orderId, ...location}));
        dispatch(paymentSummaryAction({...paymentSummary, selectedDeliveryAddress}));
        setCurrentStep(3);
        navigate('/makepayment', { replace: true });
      } else {
        setShowError(true);
      }
      handleLoader(false);
  }
  const addNewAddress = () => {
    setShowNewAddress(true);
  }
  const hideNewAddress = () => {
    setShowNewAddress(false);
  }
  const addNewAddressFn = (responseBody) => {
    handleLoader(true);
    const tempAdd = `${responseBody?.hno} ${responseBody?.street} ${responseBody?.city} ${responseBody?.state} ${responseBody?.pincode}`;
    setSelectedDeliveryAddress(tempAdd);
    dispatch(setAddressAction({...responseBody, subId, defaultAddress: 'no', ...location}));
    setShowNewAddress(false);
    handleLoader(false);
  }
  const handleOnChange = (address) => {
    setSelectedDeliveryAddress(address);
  }
  const setAsdefaultAddress = (address) => {
    handleLoader(true);
    dispatch(setDefaultAddressAction({...address, defaultAddress: 'yes', subId, ...location}));
    handleLoader(false);
  }
  const handleUserLocation = (location) => {
    setUserLocation(location);
  }

  return (
    <div className='container'>
      <Steps currentStep={currentStep} />
      <div className='outer-border pd-20'>
        <div className='row'>
          <div className='col imgView'>
            <img src={tiffinImg} alt='Tiffin' className='tiffinImg' />
          </div>
          <div className='col addressView'>
            {showError && (<div className='row wd-100 text-left mb-10 mt-10 ml-10 text-red'>Confirm Delivery Address?</div>)}
              <form onSubmit={confirmDeliveryAddress}>
                {
                  deliveryAddress?.map((address, index) => {
                    return (
                      <div key={index.toString()}>
                        <input type="radio" id={index} name="age" value={address} onChange={() => handleOnChange(address?.mainaddress)} />
                        <label htmlFor={index} className='ml-10'>{address?.mainaddress}</label>{!(address?.defaultAddress === 'yes') ? (<div onClick={() => setAsdefaultAddress(address)} className='setAsDefault'>Set as Default Address</div>) : ''}<br />
                      </div>)
                  })
                }
                <br />
                <input type="submit" value="Deliver To This Address" className='primary-button' />
              </form>
              {
                showNewAddress ? (<AddAddress 
                  hideNewAddress={hideNewAddress} 
                  addNewAddress={addNewAddressFn}  
                />) : (<div onClick={addNewAddress} className='text-primary mt-20'>Add New Address</div>)
              }
          </div>
        </div>
      </div>
      <div className='text-primary mt-20'>Please mark your delivery location below as well for better delivery.</div>
      <div className='mapContainer'>
        <MapContainer google={window.google} getLocation={handleUserLocation} />
      </div>
    </div>
  );
};

export default DeliveryAddress;
