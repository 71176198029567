import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUserAction } from '../redux_files/actions/users_actions';
import { pageAction } from '../redux_files/actions/page_actions';

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = 'Shivay Durga Foods';
    dispatch(pageAction('Shivay Durga Foods'));
  }, []);

  useEffect(() => {
    dispatch(logoutUserAction());
    navigate('/', { replace: true });
  })
  return (
    <div className='container'></div>
  );
};

export default Logout;
