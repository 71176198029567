import * as types from '../adminTypes';

const initialState = {
    saveUserResponse: undefined,
    usersData: [],
    selectedUser: undefined,
    updateUserResponse: undefined
}

const adminReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.SAVE_USER:
            return {
                ...state
            }
        case types.SAVE_USER_RESPONSE:
            return {
                ...state,
                saveUserResponse: action.payload
            }
        case types.GET_USER:
            return {
                ...state
            }
        case types.GET_USER_RESPONSE:
            return {
                ...state,
                usersData: action.payload
            }
        case types.SELECTED_USER:
            return {
                ...state,
                selectedUser: action.payload
            }
        case types.UPDATE_USER:
        return {
            ...state
        }
        case types.UPDATE_USER_RESPONSE:
        return {
            ...state,
            updateUserResponse: action.payload
        }
        default: 
            return state;
    }
}
export default adminReducer;