export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_RESPONSE = "SEND_OTP_RESPONSE";
export const SHOW_LOADER = "SHOW_LOADER";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_RESPONSE = "VERIFY_OTP_RESPONSE";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_RESPONSE = "REGISTER_USER_RESPONSE";
export const SEND_OTP_LOGIN = "SEND_OTP_LOGIN";
export const SEND_OTP_LOGIN_RESPONSE = "SEND_OTP_LOGIN_RESPONSE";
export const LOGOUT_USER = "LOGOUT_USER";
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_RESPONSE = "ADD_TO_CART_RESPONSE";
export const GET_ADDRESS = "GET_ADDRESS";
export const GET_ADDRESS_RESPONSE = "GET_ADDRESS_RESPONSE";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_ADDRESS_RESPONSE = "SET_ADDRESS_RESPONSE";
export const SET_DEFAULT_ADDRESS = "SET_DEFAULT_ADDRESS";
export const SET_DEFAULT_ADDRESS_RESPONSE = "SET_DEFAULT_ADDRESS_RESPONSE";
export const UPDATE_DELIVERY_ADDRESS = "UPDATE_DELIVERY_ADDRESS";
export const UPDATE_DELIVERY_ADDRESS_RESPONSE = "UPDATE_DELIVERY_ADDRESS_RESPONSE";
export const PAYMENT_SUMMARY_ACTION = "PAYMENT_SUMMARY_ACTION";
export const CHECK_IF_USER_EXISTS = "CHECK_IF_USER_EXISTS";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const SAVE_LOGGED_IN_USER = "SAVE_LOGGED_IN_USER";
export const SET_PAGE_TITLE = "SET_PAGE_TITLE";
export const CHECK_USER_IS_AUTHORISED = "CHECK_USER_IS_AUTHORISED";
export const CHECK_USER_IS_AUTHORISED_RESPONSE = "CHECK_USER_IS_AUTHORISED_RESPONSE";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_RESPONSE = "REFRESH_TOKEN_RESPONSE";
export const SAVE_PAYMENT_DETAILS = "SAVE_PAYMENT_DETAILS";
export const SAVE_PAYMENT_DETAILS_RESPONSE = "SAVE_PAYMENT_DETAILS_RESPONSE";
const types = {
    SEND_OTP,
    SEND_OTP_RESPONSE,
    SHOW_LOADER,
    VERIFY_OTP,
    VERIFY_OTP_RESPONSE,
    REGISTER_USER,
    REGISTER_USER_RESPONSE,
    SEND_OTP_LOGIN,
    SEND_OTP_LOGIN_RESPONSE,
    LOGOUT_USER,
    ADD_TO_CART,
    ADD_TO_CART_RESPONSE,
    GET_ADDRESS,
    GET_ADDRESS_RESPONSE,
    SET_ADDRESS,
    SET_ADDRESS_RESPONSE,
    SET_DEFAULT_ADDRESS,
    SET_DEFAULT_ADDRESS_RESPONSE,
    UPDATE_DELIVERY_ADDRESS,
    UPDATE_DELIVERY_ADDRESS_RESPONSE,
    PAYMENT_SUMMARY_ACTION,
    CHECK_IF_USER_EXISTS,
    GET_USER_DETAILS,
    SAVE_LOGGED_IN_USER,
    SET_PAGE_TITLE,
    CHECK_USER_IS_AUTHORISED,
    CHECK_USER_IS_AUTHORISED_RESPONSE,
    REFRESH_TOKEN,
    REFRESH_TOKEN_RESPONSE,
    SAVE_PAYMENT_DETAILS,
    SAVE_PAYMENT_DETAILS_RESPONSE
}
export default types;
