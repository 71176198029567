import React from 'react';

const ContactUs = () => {
  return (
    <div className='text-center text-size-24 pd-20'>
      Rs. 80 per tiffin, order 45 minutes before <br />
      For Order please call us at <a href='tel:+919996212490'>9996212490</a>
    </div>
  );
};
export default ContactUs;
