import jwtDecode from 'jwt-decode';

export const getDeviceType = () => {
  if (window.innerWidth < 500) return 'mobile';
  if (window.innerWidth > 500 && window.innerWidth < 768) return 'tablet';
  if (window.innerWidth > 768 && window.innerWidth < 992) return 'stablet';
  if (window.innerWidth > 992 && window.innerWidth < 1200) return 'sdesktop';
  if (window.innerWidth > 992 && window.innerWidth < 1200) return 'mdesktop';
  return 'desktop';
};
export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export const setCookie = (name, value) => {
  document.cookie = name +'='+ value +'; Path=/;';
}
export const deleteCookie = (name) => {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
export const getUserName = (userDetails) => {
  const { accessToken = '' } = userDetails ?? {};
  if(accessToken){
    const details = jwtDecode(accessToken);
    return details?.data?.[0]?.username;
  } else {
    return undefined;
  }
}
export const getSubId = (userDetails) => {
  const { accessToken = '' } = userDetails ?? {};
  if(accessToken){
    const details = jwtDecode(accessToken);
    return details?.data?.[0]?.subId;
  } else {
    return undefined;
  }
}
export const getUserId = (userDetails) => {
  const { accessToken = '' } = userDetails ?? {};
  if(accessToken){
    const details = jwtDecode(accessToken);
    return details?.data?.[0]?.userId;
  } else {
    return undefined;
  }
}
export const getCustomerRole = (userDetails) => {
  const { accessToken = '' } = userDetails ?? {};
  if(accessToken){
    const details = jwtDecode(accessToken);
    return details?.data?.[0]?.customerRole;
  } else {
    return undefined;
  }
}
export const getDirections = (formattedDestination) => {
  const url = `https://www.google.com/maps/dir/Current+Location/${formattedDestination}`;
  window.open(url, '_blank', 'noreferrer');
}
