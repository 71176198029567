import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loader from './loader';
import usersReducer from './users';
import productReducer from './products';
import pageReducer from './page';
import adminReducer from './admin';

const reducers = combineReducers({
    loader,
    usersReducer,
    productReducer,
    pageReducer,
    adminReducer
})
const persistConfig = {
    key: "key",
    root: "root",
    storage,
  };


const rootReducer = persistReducer(persistConfig, reducers);
export default rootReducer;