import { all, fork } from 'redux-saga/effects';
import sendOTPSaga, { verifyOTPSaga, registerUserSaga, sendOTPloginUserSaga, getAddressSaga, setAddressSaga, setDefaultAddressSaga, getUserSaga, checkAuthUserSaga, refreshTokenSaga,  logoutSaga }  from './user_saga';
import { addToCartSaga, updateDeliveryAddressSaga, savePaymentDetailsSaga } from './product_saga';
import { saveUserSaga, getUsersSaga, updateUserSaga } from './admin_saga';

const rootSaga = function* () {
    yield all([
        fork(verifyOTPSaga),
        fork(sendOTPSaga),
        fork(registerUserSaga),
        fork(sendOTPloginUserSaga),
        fork(addToCartSaga),
        fork(getAddressSaga),
        fork(setAddressSaga),
        fork(setDefaultAddressSaga),
        fork(updateDeliveryAddressSaga),
        fork(getUserSaga),
        fork(checkAuthUserSaga),
        fork(savePaymentDetailsSaga),
        fork(refreshTokenSaga),
        fork(saveUserSaga),
        fork(logoutSaga),
        fork(getUsersSaga),
        fork(updateUserSaga)
    ]);
};
  
  export default rootSaga;