import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pageAction } from '../redux_files/actions/page_actions';


const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = 'Privacy Policy';
    dispatch(pageAction('Privacy Policy'));
  });
  return (
    <div className='container'>
      <div className='outer-border pd-20'>
        Use of personal Data
        <br />
        All Personal Data that the End User provides to Shivay Durga, will be used by Shivay Durga in the context of providing (intermediary)
        services in connection with the execution of the Agreement.
        <br />
        End User will ensure that the Personal Data that it provides is complete, accurate and up to date and that it is legally entitled to
        make use of this website.
        <br />
        Why does Shivay Durga collect, use and share your personal details? Customer service : Sharing your details with our customer service
        allows for a quick response when you need us – including helping you find an appropriate entertainment and any questions you might
        have about your reservation.
        <br />
        Fraud detection and prevention : We may use personal information for the detection and prevention of fraud and other illegal or
        unwanted activities.
        <br />
        Other communications : There may be other times when we get in touch by email, by post, by phone or by texting you, depending on the
        contact information you share with us. The reason for this is that we may need to respond to and handle requests you have made.
        <br />
        Improving our services : Finally, we use personal information for analytical purposes, to improve our services, enhance the user
        experience, and improve the functionality and quality of our online travel services.
        <br />
        Shivay Durga will not provide your personal data to third parties.
        <br />
        Shivay Durga will also provide your personal data to third parties if it is obliged to do so on account of national or international
        laws, case law and/or regulations or if Shivay Durga considers it necessary to do so in defense of its own rights.
        <br />
        Shipping Policy: As soon as order is placed for either lunch or dinner. Fresh Item would be delivered within two hours of order.
        Return Policy: If we are unable to deliver the Tiffin on time due to any reason, we will refund the exact amount paid for the service.
      </div>
    </div>
  );
};
export default PrivacyPolicy;
