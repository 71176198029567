import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pageAction } from '../redux_files/actions/page_actions';

const Aboutus = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'About Us';
    dispatch(pageAction('About Us'));
  });
  return (
    <>
      <div className='container'>
        <div className='aboutus outer-border pd-20'>
          Our mission is to provide homemade food to people staying away from home and are compelled to eat Masale wala food. We will
          provide simple food to all at very low cost.
          <br />
          <br />
          This idea came to our mind when we visited some other city once for some work, there we struggled a lot to get simple homemade
          food and even after trying different restaurants we were unable to get homemade food.
          <br />
          <br />
          We are just starting our business and aim is to provide home style food to those who can not cook their own at a very affordable
          price.
        </div>
      </div>
    </>
  );
};

export default Aboutus;
