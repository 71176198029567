import axios from 'axios';
import { hostname } from './config';
import store from '../store';

const api = axios.create({
  baseURL: hostname
});

// Request interceptor for adding the bearer token
api.interceptors.request.use(
  (config) => {
    const token = store?.getState()?.usersReducer?.loggedInUserDetails?.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Content-Type'] = 'application/json charset=UTF-8';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    switch (error.response.status) {
      case 404:
        alert('Unexpected Error Occured, please try again later');
        break;
      case 500:
        alert('Unexpected Error Occured, please try again later');
        break;
      case 400:
        window.location.href = "/";
        break;
      case 401:
        window.location.href = "/logout";
        break;
      case 403:
        window.location.href = "/refresh";
        break;
      default:
        break;
    }
    return Promise.reject(error);
  }
);
// Export the api instance
export default api;