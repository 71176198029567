import React, { useState } from 'react';
import PropTypes from 'prop-types';

const AddAddress = ({hideNewAddress, addNewAddress}) => {
	const formData = {hno: "", street: "", city: "Panipat", state: "Haryana", pincode: "132103", tnc: "on"};
	const [responseBody, setResponseBody] = useState(formData);

	const inputChangeHandler = (event) => {
		const {name, value} = event.target;
		setResponseBody({...responseBody, [name]: value});
	}
const handleNewAddress = (event) => {
    event.preventDefault();
    addNewAddress({...responseBody});
}
  const renderCompleteForm = () => {
    return (
      <form onSubmit={handleNewAddress} method="post">
        <div className='row wd-100 text-left mb-10 mt-10'>
          <div className='col wd-20 display-flex'><label className='align-to-center text-size-16'>House/Office No.<span className='required'>*</span></label></div>
          <input className='col display-flex align-to-center text-size-12 ml-10 pd-10 wd-40 input-box' type="text" placeholder="Enter House Number" name="hno" required pattern="[1-9]\d*" title="Please enter only digits" onChange={(e)=>inputChangeHandler(e)} />
        </div>
        <div className='row wd-100 text-left mb-10 mt-10'>
          <div className='col wd-20 display-flex'><label className='align-to-center text-size-16'>Enter Street No.<span className='required'>*</span></label></div>
          <input className='col display-flex align-to-center text-size-12 ml-10 pd-10 wd-40 input-box' type="text" placeholder="Enter Street" name="street" required pattern=".{3,}" title="Please enter at least 3 characters" onChange={(e)=>inputChangeHandler(e)} />
        </div>
        <div className='row wd-100 text-left mb-10 mt-10'>
          <div className='col wd-20 display-flex'>
            <label className='align-to-center text-size-16'>City<span className='required'>*</span></label>
          </div>
          <input value="Panipat" className='col display-flex align-to-center text-size-12 ml-10 pd-10 wd-40 input-box' type="text" placeholder="Enter City" name="city" required pattern="[a-zA-Z ]*" title="Please enter at least 3 characters" onChange={(e)=>inputChangeHandler(e)} />
        </div>
        <div className='row wd-100 text-left mb-10 mt-10'>
          <div className='col wd-20 display-flex'><label className='align-to-center text-size-16'>State<span className='required'>*</span></label></div>
          <input value="Haryana" className='col display-flex align-to-center text-size-12 ml-10 pd-10 wd-40 input-box' type="text" placeholder="Enter State" name="state" required pattern="[a-zA-Z ]*" title="Please enter at least 3 characters" onChange={(e)=>inputChangeHandler(e)} />
        </div>
        <div className='row wd-100 text-left mb-10 mt-10'>
          <div className='col wd-20 display-flex'><label className='align-to-center text-size-16'>Pincode<span className='required'>*</span></label></div>
          <input value="132103" className='col display-flex align-to-center text-size-12 ml-10 pd-10 wd-40 input-box' type="number" placeholder="Enter Pincode" name="pincode" required pattern="[0-9]{6}" maxLength="6" title="Please enter at least 6 digits" onChange={(e)=>inputChangeHandler(e)} />
        </div>
        <div className='row wd-100 text-left mb-10 mt-10'>
          <div className='col wd-20 display-flex'><label className='align-to-center text-size-16'>Address Type</label></div>
          <select className='col display-flex align-to-center text-size-12 ml-10 pd-10 wd-50' name="workOrHome" id="workOrHome" required onChange={(e)=>inputChangeHandler(e)}>
            <option value="">Select Address Type</option>
            <option value="work">Work</option>
            <option value="home">Home</option>
          </select>
        </div>
        <div className='row wd-100 text-left mb-10 mt-20'>
          <div className='col wd-50 display-flex'>
            <button type="submit" className='primary-button' name='saveAddress'>Save Address</button>
          </div>
          <div className='col wd-50 display-flex'>
            <button type="button" className='secondary-button' onClick={hideNewAddress}>Cancel</button>
          </div>
        </div>
      </form>
    )
  }

  return (
    <div className=''>
      <div className='inner-container'>
          {renderCompleteForm()}
      </div>	
    </div>
  );
};
AddAddress.defaultProps = {
    hideNewAddress: () => {},
    addNewAddress: () => {}
};

AddAddress.propTypes = {
    hideNewAddress: PropTypes.func,
    addNewAddress: PropTypes.func
};
export default AddAddress;
