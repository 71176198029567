import React, { useEffect, useState, Suspense } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import showHideLoaderAction from '../../redux_files/actions/loader_action';
import { pageAction } from '../../redux_files/actions/page_actions';
import { getUserAction, setCurrentUser } from '../../redux_files/actions/admin_actions';

const AdminHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tempData = useSelector(state => state.adminReducer.usersData);
	const [data, setData] = useState([]);
	useEffect(() => {
    if(tempData?.usersData?.length > 0) setData(tempData?.usersData);
	}, [tempData]);

  useEffect(() => {
    document.title = 'Shivay Durga Foods';
    dispatch(pageAction('Shivay Durga Foods'));
  }, [document.title]);

  useEffect(() => {
    handleLoader(true);
    dispatch(getUserAction());
    handleLoader(false);
  },[]);
  const handleLoader = (loaderState) => {
		dispatch(showHideLoaderAction(loaderState));
	}
  const editUser = (user) => {
    dispatch(setCurrentUser(user));
    navigate('/edituser', { replace: true });
  }
  return (
    <div className='container'>
      <div className='text-left auto-margin text-primary'>
          <Link to='/adduser'>Add New User</Link>
      </div>
      <Suspense fallback={<div>Loading......</div>}>
      <table className='wd-100 text-center'>
        <tr>
          <th>Name</th>
          <th>Mobile</th>
          <th>Action</th>
        </tr>
        {
          data?.map((user, index) => {
            return(
              <tr key={index}>
                <td className='pd-10'>{user?.username}</td>
                <td><a href={`tel:+91${user?.mobile}`}>{user?.mobile}</a></td>
                <td className='text-primary' onClick={() => editUser(user)}>Edit</td>
              </tr>
            )})
        }
        </table>
      </Suspense>
    </div>
  );
};

export default AdminHome;
