import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pageAction } from '../redux_files/actions/page_actions';


const TermsAndConditions = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = 'Terms and Conditions';
    dispatch(pageAction('Terms and Conditions'));
  });
  return (
    <div className='container'>
      <div className='outer-border pd-20'>
        The General Terms and Conditions below apply to all offers and transactions of Shivay Durga. Prices are subject to change. By
        accepting an offer or making an order, the consumer expressly accepts the applicability of these General Terms and Conditions.
        Deviations from that stipulated in these Terms and Conditions are only valid when they are confirmed in writing by the management.
        <br />
        <br />
        The tiffin services guarantees that all the products offered are hygienic and fresh. If there are any complaints the management needs
        to be informed immediately. Appropriate actions will be taken as soon as possible.
        <br />
        <br />
        All offers made by Shivay Durga are without obligation and Shivay Durga expressly reserves the right to change the prices, in
        particular if this is necessary as a result of statutory or other regulations. All prices are indicated in Rupess, including GST. In
        certain cases, promotional prices apply. These prices are valid only during a specific period as long as stocks last. No entitlement
        to these prices may be invoked before or after the specific period. Shivay Durga cannot be held to any price indications that are
        clearly incorrect, for example as a result of obvious typesetting or printing errors. No rights may be derived from incorrect price
        information.
        <br />
        <br />
        Shivay Durga is entitled to cancel or change the date of an event. Should this happen, Shivay Durga will attempt to provide a suitable
        solution. If an event is cancelled or postponed, Shivay Durga will do its utmost to inform you as soon as possible. However, Shivay
        Durga cannot guarantee it is possible to inform you timely of any change or cancellation of an event or be held responsible for
        refunds, compensations or for any resulting costs you may incur. Before confirming your order, please ensure you order correct food,
        as afterwards amount wont be refundable. All purchases are final.
        <br />
        <br />
        All prices are including GST. Methods of payment we accept: Cash, paytm, google pay etc. You will not receive confirmation of your
        definitive order until your payment has been approved.
        <br />
        <br />
        If one or more of the provisions in these Terms and Conditions or any other agreement with Shivay Durga are in conflict with any
        applicable legal regulation, the provision in question will lapse and be replaced by a new comparable stipulation admissible by law to
        be determined by Shivay Durga.
      </div>
    </div>
  );
};
export default TermsAndConditions;
