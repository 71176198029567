import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import showHideLoaderAction from '../../redux_files/actions/loader_action';
import { pageAction } from '../../redux_files/actions/page_actions';
import { saveUserAction, saveUserResponseAction } from '../../redux_files/actions/admin_actions';
import products from '../../json_config/product.json';
import { SUCCESS_200 } from '../../utils/user';

const AddUser = () => {
	const formData = {
		mobile: "",
		name: "",
		hno: "",
		street: "",
		city: "Panipat",
		state: "Haryana",
		pincode: "132103",
		workOrHome: "",
		email: "",
		amount: "",
		paymentMode: "",
		securityAmountStatus: "",
		securityAmount: "",
		quantity: "",
		tiffinStatus: ""
	};
	const navigate = useNavigate();
	const saveUserResponse = useSelector(state => state.adminReducer.saveUserResponse);
	
	const [responseBody, setResponseBody] = useState(formData);
	const inputChangeHandler = (event) => {
		const { name, value } = event.target;
		setResponseBody({ ...responseBody, [name]: value });
	}
	const dispatch = useDispatch();
	const handleLoader = (loaderState) => {
		dispatch(showHideLoaderAction(loaderState));
	}
	const addNewUser = (event) => {
		handleLoader(true);
		event.preventDefault();
		dispatch(saveUserAction(responseBody));
		handleLoader(false);
	}
	useEffect(() => {
		document.title = 'Add User';
		dispatch(pageAction('Add User'));
	}, [document.title]);

	useEffect(() => {
		if (saveUserResponse?.code === SUCCESS_200) {
			dispatch(saveUserResponseAction({}));
			alert('User Created');
			navigate('/admin', { replace: true });
		}
		if(saveUserResponse?.message === "User Already Exists") {
			dispatch(saveUserResponseAction({}));
			alert('User already exists with given mobile number');
			navigate('/admin', { replace: true });
		}
	}, [saveUserResponse]);


	return (
		<div className='container'>
			<div className='inner-container'>
				<div className='text-center auto-margin'>
					Add New User
				</div>
				<div className='outer-box'>
					<form onSubmit={addNewUser} method="post">
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Mobile<span className='required'>*</span></label></div>
							<input className='col display-flex text-left text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter Mobile" name="mobile" required pattern="[0-9]{10}" maxLength="10" title="Please enter at least 10 digits" onChange={(e) => inputChangeHandler(e)} />
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Name<span className='required'>*</span></label></div>
							<input className='col display-flex text-left text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter Name" name="username" required pattern=".{3,}" title="Please enter at least 3 characters" onChange={(e) => inputChangeHandler(e)} />
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>House/Office No.<span className='required'>*</span></label></div>
							<input className='col display-flex text-left text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter House/Office Number" name="hno" pattern="[1-9]\d*" maxLength="5" title="Please enter only digits" onChange={(e) => inputChangeHandler(e)} />
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Address<span className='required'>*</span></label></div>
							<input className='col display-flex text-left text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter Address" name="street" pattern=".{3,}" title="Please enter at least 3 characters" onChange={(e) => inputChangeHandler(e)} />
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'>
								<label className='text-left text-size-16'>City<span className='required'>*</span></label>
							</div>
							<input value="Panipat" className='col display-flex text-left text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter City" name="city" required pattern="[a-zA-Z ]*" title="Please enter at least 3 characters" onChange={(e) => inputChangeHandler(e)} />
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>State<span className='required'>*</span></label></div>
							<input value="Haryana" className='col display-flex text-left text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter State" name="state" required pattern="[a-zA-Z ]*" title="Please enter at least 3 characters" onChange={(e) => inputChangeHandler(e)} />
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Pincode<span className='required'>*</span></label></div>
							<input className='col display-flex text-left text-size-12 ml-10 pd-10 input-box' value="132103" type="text" placeholder="Enter Pincode" name="pincode" required pattern="[0-9]{6}" maxLength="6" title="Please enter at least 6 digits" onChange={(e) => inputChangeHandler(e)} />
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Email</label></div>
							<input className='col display-flex text-left text-size-12 ml-10 pd-10 input-box' type="email" placeholder="Enter email" name="email" onChange={(e) => inputChangeHandler(e)} />
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Amount<span className='required'>*</span></label></div>
							<input className='col display-flex text-left text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter Amount" name="amount" pattern="[0-9]\d*" maxLength="6" onChange={(e) => inputChangeHandler(e)} />
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Address Type</label></div>
							<select className='col display-flex text-left text-size-12 pd-10 input-box' name="workOrHome" id="workOrHome" required onChange={(e) => inputChangeHandler(e)}>
								<option value="">Select Address Type</option>
								<option value="work">Work</option>
								<option value="home">Home</option>
							</select>
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Mode Of Payment</label></div>
							<select className='col display-flex text-left text-size-12 pd-10 input-box' name="paymentMode" id="paymentMode" required onChange={(e) => inputChangeHandler(e)}>
								<option value="">Select Payment Mode</option>
								<option value="cash">Cash</option>
								<option value="online">Online</option>
							</select>
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Security Amount Received</label></div>
							<select className='col display-flex text-left text-size-12 pd-10 input-box' name="securityAmountStatus" id="securityAmountStatus" required onChange={(e) => inputChangeHandler(e)}>
								<option value="">Select</option>
								<option value="cash">Yes</option>
								<option value="online">No</option>
							</select>
						</div>
						<div className='row wd-100 text-center mb-10 mt-20'>
							<div className='labelDiv'><label className='text-left text-size-16'>Security Amount<span className='required'>*</span></label></div>
							<input className='col display-flex text-left text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter Security Amount" name="securityAmount" pattern="[0-9]\d*" maxLength="6" onChange={(e) => inputChangeHandler(e)} />
						</div>
						<div className='row wd-100 text-center mb-10 mt-20'>
							<div className='labelDiv'><label className='text-left text-size-16'>Quantity</label></div>
							<input className='col display-flex text-left text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter Quantity" name="quantity" pattern="[0-9]\d*" maxLength="6" onChange={(e) => inputChangeHandler(e)} />
						</div>

						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Tiffin Status</label></div>
							<select className='col display-flex text-left text-size-12 pd-10 input-box' name="tiffinStatus" id="tiffinStatus" onChange={(e) => inputChangeHandler(e)}>
								<option value="">Select Tiffin Delivery Status</option>
								<option value="outForDelivery">Out For Delivery</option>
								<option value="delivered">Delivered</option>
								<option value="notDelivered">Not Delivered</option>
								<option value="picked">Return Picked</option>
								<option value="notPicked">Return Not Picked</option>
							</select>
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Product Ordered</label></div>
							<select name="tiffin" id="tiffin" required className="col display-flex text-left text-size-12 pd-10 input-box" onChange={(e) => inputChangeHandler(e)}>
								<option value="">Select Tiffin</option>
								{
									products.map(product => {
										return (<option key={product.key} value={product.key}>{product.value}</option>)
									})
								}
							</select>
						</div>

						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='buttonDiv'>
								<button type="submit" className='primary-button' name='addUser'>Add User</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddUser;

