import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import { MAP_API_KEY } from '../api/config';
import { getDeviceType } from '../utils/common';

const MapContainer = (props) => {
    const [map, setMap] = useState(null);
    const [deviceType, setDeviceType] = useState(getDeviceType());
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState({});
    const [activeMarker, setActiveMarker] = useState({});
    const [position, setPosition] = useState(undefined);

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setPosition({
                    latitude: position.coords.latitude,
                    longitude: -position.coords.longitude,
                });
            });
        } else {
          console.log("Geolocation is not available in your browser.");
        }
    }, []);
    useEffect(() => {
        // eslint-disable-next-line react/prop-types
        props.getLocation(position);
        setDeviceType(getDeviceType());
    },[position]);
    const onMarkerClick = (props, marker, e) => {
        console.log("props", props, e, map);
        setActiveMarker(marker);
        setSelectedPlace(props);
        setShowingInfoWindow(true);
    }
    const onMapClicked = (mapProps, map, clickEvent) => {
        console.log("mapProps, map, clickEvent", mapProps, map, clickEvent);
        setPosition({
            latitude: clickEvent.latLng.lat(),
            longitude: -clickEvent.latLng.lng(),
        });
        if (showingInfoWindow) {
            setShowingInfoWindow(false);
            setActiveMarker(position);
        }
    }
    const getMapStyle = () => {
        switch (deviceType) {
            case "mobile":
                return {width: "90%", margin: "auto", height: "300px"};
            case "tablet":
                return {width: "75%", margin: "auto", height: "300px"};
            case "stablet":
                return {width: "90%", margin: "auto", height: "300px"};
            case "sdesktop":
                return {width: "70%", margin: "auto", height: "300px"};
            case "mdesktop":
                return {width: "60%", margin: "auto", height: "300px"};
            default:
                return {width: "60%", margin: "auto", height: "500px"};
        }
    }
  return (
    <>
    {
        position ? (<Map
            // eslint-disable-next-line react/prop-types
            google={props.google}
            zoom={14}
            initialCenter={{ lat: 29.3909, lng: -76.9635 }}
            center={{lat: position?.latitude, lng: -position?.longitude}}
            onReady={(mapProps, map) => setMap(map)}
            onClick={onMapClicked}
            containerStyle={getMapStyle()}
        >
            <Marker onClick={onMarkerClick} name={'Current location'} position={{lat: position?.latitude, lng: -position?.longitude}} />
            <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
                <div>
                    <h1>{selectedPlace.name}</h1>
                </div>
            </InfoWindow>
        </Map>) : <div className='text-red text-bold text-center'>Please grant location permissions for better delivery</div>
    }
    </> 
  );
}
export default GoogleApiWrapper({
  apiKey: MAP_API_KEY
})(MapContainer);
