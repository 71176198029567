import * as types from "../types";

export const addToCartAction = (product) =>  {
    return {
        type: types.ADD_TO_CART,
        payload: product
    }
}
export const addToCartResponseAction = (responseData) =>  {
    return {
        type: types.ADD_TO_CART_RESPONSE,
        payload: responseData?.data ?? {}
    }
}
export const updateDeliveryAddressAction = (payload) =>  {
    return {
        type: types.UPDATE_DELIVERY_ADDRESS,
        payload
    }
}
export const updateDeliveryAddressResponseAction = (responseData) =>  {
    return {
        type: types.UPDATE_DELIVERY_ADDRESS_RESPONSE,
        payload: responseData?.data ?? {}
    }
}
export const paymentSummaryAction = (payload) =>  {
    return {
        type: types.PAYMENT_SUMMARY_ACTION,
        payload
    }
}
export const savePaymentDetails = (payload) =>  {
    return {
        type: types.SAVE_PAYMENT_DETAILS,
        payload
    }
}
export const savePaymentDetailsResponse = (responseData) =>  {
    return {
        type: types.SAVE_PAYMENT_DETAILS_RESPONSE,
        payload: responseData?.data ?? {}
    }
}