import * as types from "../types";

const sendOTPAction = (mobile) =>  {
    return {
        type: types.SEND_OTP,
        payload: mobile
    }
}
export const sendOTPResponseAction = (responseData) =>  {
    return {
        type: types.SEND_OTP_RESPONSE,
        payload: responseData?.data ?? {}
    }
}
export const verifyOTPAction = (mobileOrOtp) =>  {
    return {
        type: types.VERIFY_OTP,
        payload: mobileOrOtp
    }
}
export const verifyOTPResponseAction = (responseData) =>  {
    return {
        type: types.VERIFY_OTP_RESPONSE,
        payload: responseData?.data ?? {}
    }
}
export const registerUserAction = (payload) =>  {
    return {
        type: types.REGISTER_USER,
        payload: payload
    }
}
export const registerUserResponseAction = (responseData) =>  {
    return {
        type: types.REGISTER_USER_RESPONSE,
        payload: responseData?.data ?? {}
    }
}
export const sendOTPLoginAction = (mobileOrOtp) =>  {
    return {
        type: types.SEND_OTP_LOGIN,
        payload: mobileOrOtp
    }
}
export const sendOTPLoginResponseAction = (responseData) =>  {
    return {
        type: types.SEND_OTP_LOGIN_RESPONSE,
        payload: responseData?.data ?? {}
    }
}
export const saveLoggedInUser = (responseData) =>  {
    return {
        type: types.SAVE_LOGGED_IN_USER,
        payload: responseData?.data ?? {}
    }
}
export const getAddressAction = (subId) =>  {
    return {
        type: types.GET_ADDRESS,
        payload: subId
    }
}
export const getAddressResponseAction = (responseData) =>  {
    return {
        type: types.GET_ADDRESS_RESPONSE,
        payload: responseData?.data ?? {}
    }
}
export const setAddressAction = (address) =>  {
    return {
        type: types.SET_ADDRESS,
        payload: address
    }
}
export const setAddressResponseAction = (responseData) =>  {
    return {
        type: types.SET_ADDRESS_RESPONSE,
        payload: responseData?.data ?? {}
    }
}
export const setDefaultAddressAction = (address) =>  {
    return {
        type: types.SET_DEFAULT_ADDRESS,
        payload: address
    }
}
export const setDefaultAddressResponseAction = (responseData) =>  {
    return {
        type: types.SET_DEFAULT_ADDRESS_RESPONSE,
        payload: responseData?.data ?? {}
    }
}
export const checkIfUserExistsAction = (subId) =>  {
    return {
        type: types.CHECK_IF_USER_EXISTS,
        payload: subId
    }
}
export const getUserResponseAction = (responseData) => {
    return {
        type: types.GET_USER_DETAILS,
        payload: responseData?.data ?? {}
    }
}
export const checkUserIsAuthAction = () => {
    return {
        type: types.CHECK_USER_IS_AUTHORISED
    }
}
export const checkUserIsAuthResponseAction = () => {
    return {
        type: types.CHECK_USER_IS_AUTHORISED_RESPONSE
    }
}
export const refreshTokenAction = (token) =>  {
    return {
        type: types.REFRESH_TOKEN,
        payload: token
    }
}
export const refreshTokenResponseAction = (responseData) =>  {
    return {
        type: types.REFRESH_TOKEN_RESPONSE,
        payload: responseData?.data ?? {}
    }
}
export const logoutUserAction = () =>  {
    return {
        type: types.LOGOUT_USER
    }
}
export default sendOTPAction;