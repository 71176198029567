import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from "redux-saga";
import rootSaga from './redux_files/middleware/root_saga';
import rootReducer from './redux_files/reducers/index';

const sagaMiddleware = createSagaMiddleware();

export default configureStore({
    reducer: rootReducer,
    middleware:()=> [sagaMiddleware],
});
sagaMiddleware.run(rootSaga);
