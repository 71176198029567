export const ERROR_201 = "ERROR_201";
export const SUCCESS_202 = "SUCCESS_202";
export const SUCCESS_200 = "SUCCESS_200";
export const ERROR_205 = "ERROR_205";
export const ERROR_204 = "ERROR_204";

const getErrorMessages = (errorCode) => {
    switch(errorCode) {
        case ERROR_201:
            return "User already registered with this number, Please login";
        case ERROR_205:
            return "Error while sending SMS try again later.";
        case ERROR_204:
            return "Invalid OTP"
        default:
            return "";
    }
};

export const getLoginErrorMessages = (errorCode) => {
    switch(errorCode) {
        case ERROR_201:
            return "User doesn't exists, Please register";
        case ERROR_205:
            return "Error while sending SMS try again later.";
        case ERROR_204:
            return "Invalid OTP"
        default:
            return "";
    }
};
export default getErrorMessages;