import * as types from '../types';

const initialState = {
    showHideLoaderInState: false
}

const toggleLoaderReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.SHOW_LOADER:
            return {
                ...state,
                showHideLoaderInState: action.payload
            }
        default: 
            return state;
    }
}
export default toggleLoaderReducer;