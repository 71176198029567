import * as types from '../types';

const initialState = {
    addToCartResponse: undefined,
    updateAddressResponse: undefined,
    paymentSummary: undefined,
    paymentDetails: undefined
}

const productReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.ADD_TO_CART:
            return {
                ...state
            }
        case types.ADD_TO_CART_RESPONSE:
            return {
                ...state,
                addToCartResponse: action.payload
            }
        case types.UPDATE_DELIVERY_ADDRESS:
            return {
                ...state
            }
        case types.UPDATE_DELIVERY_ADDRESS_RESPONSE:
            return {
                ...state,
                updateAddressResponse: action.payload
            }
        case types.PAYMENT_SUMMARY_ACTION:
            return {
                ...state,
                paymentSummary: action.payload
            }
        case types.SAVE_PAYMENT_DETAILS:
            return {
                ...state
            }
        case types.SAVE_PAYMENT_DETAILS_RESPONSE:
            return {
                ...state,
                paymentDetails: action.payload
            }
        default: 
            return state;
    }
}
export default productReducer;