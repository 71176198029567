import { takeEvery, put } from 'redux-saga/effects';
import api from '../../api/api';
import types from '../types';
import { addToCartResponseAction, updateDeliveryAddressResponseAction, savePaymentDetailsResponse } from '../actions/product_actions';

function* addToCartAPI(action) {
    try {
        const response = yield api.post('product/cart.php', {...action.payload, quantity: 1});
        yield put(addToCartResponseAction(response));
      } catch (err) {
        yield put(addToCartResponseAction(err));
      }
}
export function* addToCartSaga(){
    yield takeEvery(types.ADD_TO_CART, addToCartAPI);
}
function* updateDeliveryAddressApi(action) {
  try {
      const response = yield api.post('product/deliveryAddress.php', {orderAddress: action?.payload?.selectedDeliveryAddress, orderId: action?.payload?.orderId, ...action.payload 
      });
      yield put(updateDeliveryAddressResponseAction(response));
    } catch (err) {
      yield put(updateDeliveryAddressResponseAction(err));
    }
}
export function* updateDeliveryAddressSaga(){
  yield takeEvery(types.UPDATE_DELIVERY_ADDRESS, updateDeliveryAddressApi);
}
function* savePaymentDetailsApi(action) {
  try {
      const response = yield api.post('product/paymentDetails.php', {...action.payload 
      });
      yield put(savePaymentDetailsResponse(response));
    } catch (err) {
      yield put(savePaymentDetailsResponse(err));
    }
}
export function* savePaymentDetailsSaga(){
  yield takeEvery(types.SAVE_PAYMENT_DETAILS, savePaymentDetailsApi);
}