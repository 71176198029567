import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Steps from './steps';
import tiffinImg from '../assets/tiffin.jpg';
import { addToCartAction, addToCartResponseAction, paymentSummaryAction } from '../redux_files/actions/product_actions';
import { checkIfUserExistsAction, logoutUserAction, setAddressResponseAction } from '../redux_files/actions/users_actions';
import showHideLoaderAction from '../redux_files/actions/loader_action';
import { pageAction } from '../redux_files/actions/page_actions';
import products from '../json_config/product.json';
import { SUCCESS_200 } from '../utils/user';
import { getSubId } from '../utils/common';

const Cart = () => {
  const [selectedProduct, setSelectedProduct] = useState('');
  const [currentStep, setCurrentStep] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addToCartResponse = useSelector(state => state?.productReducer?.addToCartResponse);
  const paymentSummary = useSelector(state => state?.productReducer?.paymentSummary);
  const loggedInUserDetails = useSelector(state => state?.usersReducer?.loggedInUserDetails);
  const subId = getSubId(loggedInUserDetails);

  useEffect(() => {
    document.title = 'Order Now';
    dispatch(pageAction('Order Now'));
    dispatch(setAddressResponseAction({}));
    if(subId) {
      dispatch(checkIfUserExistsAction(subId));
    } else {
      dispatch(logoutUserAction());
      navigate('/', { replace: true });
    }
  }, []);

  useEffect(() => {
    if(addToCartResponse?.code === SUCCESS_200) {
      dispatch(paymentSummaryAction({...paymentSummary, orderId: addToCartResponse?.message}));
      setCurrentStep(2);
      dispatch(addToCartResponseAction({}));
      navigate('/deliveryAddress', { replace: true });
    }
  }, [addToCartResponse]);

  const handleLoader = (loaderState) => {
    dispatch(showHideLoaderAction(loaderState));
  }
  const addToCart = (event) => {
		event.preventDefault();
    handleLoader(true);
    dispatch(addToCartAction({...selectedProduct, subId}));
    dispatch(paymentSummaryAction({...selectedProduct, subId}));
    handleLoader(false);
	}
  const selectedTiffin = (evt) => {
    const selectedProduct = evt.target.value;
    const selectedProductObj = products?.filter(product => product.key === selectedProduct)?.[0] ?? [];
    setSelectedProduct(selectedProductObj);
  }
  return (
    <div className='container'>
      <Steps currentStep={currentStep} />
      <div className='outer-border pd-20'>
        <div className='row'>
          <div className='col imgView'>
            <img src={tiffinImg} alt='Tiffin' className='tiffinImg' />
          </div>
          <div className='col dropdownView'>
            <form onSubmit={addToCart}>
              <div className="custom-select">
                <select name="tiffin" id="tiffin" required onChange={selectedTiffin}>
                  <option value="">Select Tiffin</option>
                  {
                    products.map(product => {
                      return (<option key={product.key} value={product.key}>{product.value}</option>)
                    })
                  }
                </select>
              </div>
              {
                Object.keys(selectedProduct).length > 0 && (
                  <>
                    <div className='row mt-20'>
                      <div className='col wd-40 display-flex'>Price per Product: </div>
                      <div className='col wd-40 display-flex'>Rs.{selectedProduct?.price}</div>
                    </div>
                    <div className='row mt-10'>
                      <div className='col wd-40 display-flex'>Quantity: </div>
                      <div className='col wd-40 display-flex'>1</div>
                    </div>
                    <div className='row mt-10'>
                      <div className='col wd-40 display-flex'>Total: </div>
                      <div className='col wd-40 display-flex'>Rs.{selectedProduct?.price}</div>
                    </div>
                  </>
                )
              }
              <br />
              <input type="submit" value="Buy Now" className='primary-button' />
              </form>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
