import React from 'react';

const FoodProperty = () => {
  return (
    <div className='circle-div'>
		<div className='circle'>Hygienic</div>
		<div className='circle'>Tasty</div>
		<div className='circle'>Good Quality</div>
		<div className='circle'>Affordable Price</div>
		<div className='circle'>Pure Veg</div>
		<div className='circle'>Homemade</div>
    </div>
  );
};
export default FoodProperty;