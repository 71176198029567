import { takeEvery, put } from 'redux-saga/effects';
import api from '../../api/api';
import Cookies from 'universal-cookie';
import types from '../types';
import { sendOTPResponseAction, verifyOTPResponseAction, registerUserResponseAction, sendOTPLoginResponseAction, getAddressResponseAction, setAddressResponseAction, setDefaultAddressResponseAction, getUserResponseAction, saveLoggedInUser, checkUserIsAuthResponseAction, refreshTokenResponseAction } from '../actions/users_actions';

const cookies = new Cookies();
function* callSendOTPAPI(action) {
    try {
        const response = yield api.get(`user/getUser.php?mobile=${encodeURIComponent(action?.payload)}&sendOTPForRegister=true`);
        yield put(sendOTPResponseAction(response));
      } catch (err) {
        yield put(sendOTPResponseAction(err));
      }
}
export function* sendOTPSaga(){
    yield takeEvery(types.SEND_OTP, callSendOTPAPI);
}

function* callVerifyOTPAPI(action) {
    try {
        const { mobile, otp } = action?.payload ?? {};
        const response = yield api.get(`user/getUser.php?otp=${encodeURIComponent(otp)}&mobile=${encodeURIComponent(mobile)}&verifyOTPForRegister=true`);
        yield put(verifyOTPResponseAction(response));
      } catch (err) {
        yield put(verifyOTPResponseAction(err));
      }
}
export function* verifyOTPSaga(){
    yield takeEvery(types.VERIFY_OTP, callVerifyOTPAPI);
}
function* registeruserAPI(action) {
    try {
        const response = yield api.post('user/create.php', {...action.payload});
        yield put(registerUserResponseAction(response));
      } catch (err) {
        yield put(registerUserResponseAction(err));
      }
}
export function* registerUserSaga(){
    yield takeEvery(types.REGISTER_USER, registeruserAPI);
}
function* sendOTPloginuserAPI(action) {
    try {
        const { mobile, otp } = action?.payload ?? {};
        let params = "";
        if(mobile) {
            params = `mobile=${encodeURIComponent(mobile)}&sendOTP=true`;
        }
        if(otp) {
            params = `otp=${encodeURIComponent(otp)}&mobile=${encodeURIComponent(mobile)}&verifyUser=true`;
        }
        const response = yield api.get(`user/login.php?${params}`);
        if(response?.data?.accessToken) {
            cookies.set('accessToken', response.data.accessToken, {
                path: '/',
                httpOnly: true,
                secure: true // Set to true if using HTTPS
            });
        }
        yield put(saveLoggedInUser(response));
        yield put(sendOTPLoginResponseAction(response));
      } catch (err) {
        yield put(sendOTPLoginResponseAction(err));
      }
}
export function* sendOTPloginUserSaga(){
    yield takeEvery(types.SEND_OTP_LOGIN, sendOTPloginuserAPI);
}
function* getAddressAPI(action) {
    try {
        const response = yield api.get(`user/address.php?subId=${encodeURIComponent(action?.payload)}&getaddress=true`);
        yield put(getAddressResponseAction(response));
      } catch (err) {
        yield put(getAddressResponseAction(err));
      }
}
export function* getAddressSaga(){
    yield takeEvery(types.GET_ADDRESS, getAddressAPI);
}
function* setAddressAPI(action) {
  try {
      const response = yield api.post('user/address.php', {...action.payload, setaddress: true}) 
      yield put(setAddressResponseAction(response));
    } catch (err) {
      yield put(setAddressResponseAction(err));
    }
}
export function* setAddressSaga(){
  yield takeEvery(types.SET_ADDRESS, setAddressAPI);
}
function* setDefaultAddressAPI(action) {
  try {
      const response = yield api.post('user/address.php', {...action.payload, setDefaultAddress: "yes"}) 
      yield put(setDefaultAddressResponseAction(response));
    } catch (err) {
      yield put(setDefaultAddressResponseAction(err));
    }
}
export function* setDefaultAddressSaga(){
  yield takeEvery(types.SET_DEFAULT_ADDRESS, setDefaultAddressAPI);
}
function* getUserAPI(action) {
  try {
      const response = yield api.get(`user/getUser.php?subId=${encodeURIComponent(action?.payload)}`) 
      yield put(getUserResponseAction(response));
    } catch (err) {
      yield put(getUserResponseAction(err));
    }
}
export function* getUserSaga(){
  yield takeEvery(types.CHECK_IF_USER_EXISTS, getUserAPI);
}
function* checkUserAuthAPI() {
  try {
      const response = yield api.get(`user/login.php?checkIfUserIsAuthorized=true`);
      yield put(checkUserIsAuthResponseAction(response));
    } catch (err) {
      yield put(checkUserIsAuthResponseAction(err));
    }
}
export function* checkAuthUserSaga(){
  yield takeEvery(types.CHECK_USER_IS_AUTHORISED, checkUserAuthAPI);
}
function* refreshTokenAPI(action) {
  try {
      const response = yield api.get(`user/refreshToken.php`, { token: action.payload, refreshToken: true});
      if(response?.data?.accessToken) {
          cookies.set('accessToken', response.data.accessToken, {
              path: '/',
              httpOnly: true,
              secure: true // Set to true if using HTTPS
          });
      }
      yield put(saveLoggedInUser(response));
      yield put(refreshTokenResponseAction(response));

    } catch (err) {

      yield put(refreshTokenResponseAction(err));
    }
}
export function* refreshTokenSaga(){
  yield takeEvery(types.REFRESH_TOKEN, refreshTokenAPI);
}
function* logoutAPI() {
  try {
      const response = yield api.get(`user/logout.php`);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
}
export function* logoutSaga(){
  yield takeEvery(types.LOGOUT_USER, logoutAPI);
}
export default sendOTPSaga;

