import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import steps from '../json_config/steps.json';
import { pageAction } from '../redux_files/actions/page_actions';
import { getDeviceType } from '../utils/common';

const Steps = ({currentStep}) => {
    const dispatch = useDispatch();
    const [deviceType, setDeviceType] = useState(getDeviceType());

    useEffect(() => {
        window.addEventListener('resize', setDeviceType(getDeviceType()));
        return () => window.removeEventListener('resize', setDeviceType(getDeviceType()));
      }, []);
    useEffect(() => {
        document.title = 'Order Now';
        dispatch(pageAction('Order Now'));
    });

  return (
    <>
        <div className='row'>
            {
                steps?.map((step, index) => {
                    const isActive = step.stepNo <= currentStep;
                    return (
                        <div key={step.stepKey} className='col display-flex wd-25'>
                            <div>
                                <div className={`stepCircle ${isActive ? 'activeStepCircle' : 'inActiveStepCircle'} ${deviceType === 'mobile' ? 'ml-0' : 'ml-35'} display-flex`}>
                                    <div className='circleText'>{step.stepNo}</div>
                                </div>
                                {index + 1 < steps.length && (<div className={`line ${isActive ? 'activeLine' : 'inActiveLine'} display-flex`}></div>)}
                            </div>
                            <span className={`${isActive ? 'text-primary' : 'text-secondary'} text-bold`}>{step.stepTitle}</span>
                        </div>
                    )
                })
            }
        </div>
    </>
  );
};
Steps.defaultProps = {
    currentStep: 1
};

Steps.propTypes = {
    currentStep: PropTypes.number,
};
export default Steps;
