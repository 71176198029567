import { takeEvery, put } from 'redux-saga/effects';
import api from '../../api/api';
import types from '../adminTypes';
import { saveUserResponseAction, getUsersResponseAction, updateUsersResponseAction } from '../actions/admin_actions';

function* saveUserAPI(action) {
    try {
        const response = yield api.post('admin/saveUser.php', {...action.payload});
        yield put(saveUserResponseAction(response));
      } catch (err) {
        yield put(saveUserResponseAction(err));
      }
}
export function* saveUserSaga(){
    yield takeEvery(types.SAVE_USER, saveUserAPI);
}
function* getUserAPI() {
    try {
        const response = yield api.get('admin/getUsers.php?adminGetUser=true');
        yield put(getUsersResponseAction(response));
      } catch (err) {
        yield put(getUsersResponseAction(err));
      }
}
export function* getUsersSaga(){
    yield takeEvery(types.GET_USER, getUserAPI);
}
function* updateUserAPI(action) {
  try {
      const response = yield api.post('admin/updateUser.php', {...action.payload});
      yield put(updateUsersResponseAction(response));
    } catch (err) {
      yield put(updateUsersResponseAction(err));
    }
}
export function* updateUserSaga(){
  yield takeEvery(types.UPDATE_USER, updateUserAPI);
}