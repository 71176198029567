import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuid } from "uuid";
import Steps from './steps';
import QRCODE from '../assets/qrcode.jpeg';
import { paymentSummaryAction, savePaymentDetails } from '../redux_files/actions/product_actions';
import showHideLoaderAction from '../redux_files/actions/loader_action';
import { pageAction } from '../redux_files/actions/page_actions';

const MakePayment = () => {
  const [showTransationId, setShowTransactionId] = useState(false);
  const [paymentSummaryTemp, setPaymentSummaryTemp] = useState(undefined);
  const unique_id = uuid().substring(0,8);
  const dispatch = useDispatch();
  const paymentSummary = useSelector(state => state?.productReducer?.paymentSummary);

  useEffect(() => {
    document.title = 'Make Payment';
    dispatch(pageAction('Make Payment'));
  }, [document.title]);

  useEffect(() => {
    setPaymentSummaryTemp(paymentSummary);
  }, [paymentSummary]);

  const handleLoader = (loaderState) => {
    dispatch(showHideLoaderAction(loaderState));
  }
  const confirmOrder = () => {
    handleLoader(true);
    if(confirm('Have You taken the payment screenshot?')) {
      dispatch(paymentSummaryAction({...paymentSummary, transactionId: unique_id}));
      dispatch(savePaymentDetails({...paymentSummary, transactionId: unique_id}));
      handleLoader(false);
      setShowTransactionId(true);
      alert('Order Placed. Thank you! We will get back to you as soon as possible.')
    } else {
      handleLoader(false);
      setShowTransactionId(false)
    }
  }
  return (
    <div className='container'>
      <Steps currentStep={3} />
      <div className='outer-border pd-20'>
        <div className='row text-red'><b>Note: Please take the screenshot of payment.</b></div>
        <div className='row'>
          <div className='col qrView'>
            <img src={QRCODE} alt='Shivay Durga' width='100%' />
          </div>
          <div className='col paymentSummary'>
            {
              !showTransationId && (
              <div className='row wd-100 text-left mb-10 mt-10'>
                <div className='col wd-100 display-flex'>
                  <button type="button" className='primary-button' onClick={confirmOrder}>Confirm Order</button>
                </div>
              </div>
              )
            }
            {
              showTransationId && (<div className='row wd-100 text-left mb-10 mt-10'>
              <div className='col display-flex wd-100'>
                <label className='align-to-center text-size-16 text-bold'>Thank you for your order. We will get back to you as soon as possible.<br /> TransationID: {unique_id}</label>
              </div>
            </div>)
            }
            
            <div className='row wd-100 text-left mb-10 mt-10'>
              <div className='col display-flex wd-100'>
                <label className='align-to-center text-size-16'>Order Details</label>
              </div>
            </div>
            <div className='row wd-100 text-left mb-10 mt-10'>
              <div className='col display-flex wd-100'>
                <label className='align-to-center text-size-16'>{paymentSummaryTemp?.value}</label>
              </div>
            </div>
            <div className='row wd-100 text-left mb-10 mt-10'>
              <div className='col display-flex wd-100'>
                <label className='align-to-center text-size-16 text-bold'>Rs. {paymentSummaryTemp?.price}</label>
              </div>
            </div>
            <div className='row wd-100 text-left mb-10 mt-10'>
              <div className='col display-flex wd-100'>
                <label className='align-to-center text-size-16'>Delivery Address:  {paymentSummaryTemp?.selectedDeliveryAddress}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakePayment;
