import React, { useEffect, useState } from 'react';
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import showHideLoaderAction from '../../redux_files/actions/loader_action';
import { pageAction } from '../../redux_files/actions/page_actions';
import { updateUserAction } from '../../redux_files/actions/admin_actions';
import products from '../../json_config/product.json';
// import { SUCCESS_200 } from '../../utils/user';

const EditUser = () => {
	// const navigate = useNavigate();
	const selectedUser = useSelector(state => state.adminReducer.selectedUser);
	
	const [currentUser, setCurrentUser] = useState(selectedUser);
	const formData = {
		paymentMode: currentUser?.paymentMode,
		securityAmountStatus: currentUser?.securityAmountStatus,
		securityAmount: currentUser?.securityAmount,
		quantity: currentUser?.quantity,
		tiffinStatus: currentUser?.tiffinStatus,
		tiffin: currentUser?.tiffin
	};
	const [responseBody, setResponseBody] = useState(formData);

	const inputChangeHandler = (event) => {
		const { name, value } = event.target;
		setResponseBody({ ...responseBody, [name]: value });
	}
	const dispatch = useDispatch();
	const handleLoader = (loaderState) => {
		dispatch(showHideLoaderAction(loaderState));
	}
	const updateUser = (event) => {
		console.log("responseBody", responseBody, currentUser);
		handleLoader(true);
		event.preventDefault();
		const tiffinData = {
			tiffinId: currentUser?.tiffinId,  
			tiffinStatus: responseBody?.tiffinStatus, 
			securityAmount: responseBody?.securityAmount,
			securityAmountStatus: responseBody?.securityAmountStatus, 
			quantity: responseBody?.quantity
		};
		const productData = {
			orderid: currentUser?.orderid,
			paymentMode: responseBody?.paymentMode,
			productName: responseBody?.tiffin
		}
		dispatch(updateUserAction({tiffinData, productData}));
		handleLoader(false);
	}
	useEffect(() => {
		document.title = 'Update User';
		dispatch(pageAction('Update User'));
	}, [document.title]);

	useEffect(() => {
		setCurrentUser(selectedUser);
	}, [selectedUser]);


	return (
		<div className='container'>
			<div className='inner-container'>
				
				<div className='outer-box'>
					<form onSubmit={updateUser} method="post">
						<div className='row wd-100 ml-10 mb-10 mt-10'>
							<label className='text-left text-size-16'>Mobile: {currentUser?.mobile} </label>
						</div>
						<div className='row wd-100 mb-10 mt-10 ml-10'>
							<label className='text-left text-size-16'>Name: {currentUser?.username} </label>
						</div>
						<div className='row wd-100 ml-10 mb-10 mt-10'>
							<label className='text-left text-size-16'>Address: {currentUser?.hno} {currentUser?.street} {currentUser?.city} {currentUser?.state} {currentUser?.pincode} </label>
						</div>
						
						<div className='row wd-100 mb-10 mt-10 ml-10'>
							<label className='text-left text-size-16'>Email: {currentUser?.email} </label>
						</div>
						<div className='row wd-100 mb-10 mt-10 ml-10'>
							<label className='text-left text-size-16'>Amount: Rs. {currentUser?.amount} </label>
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Mode Of Payment</label></div>
							<select className='col display-flex text-left text-size-12 pd-10 input-box' name="paymentMode" id="paymentMode" required onChange={(e) => inputChangeHandler(e)}>
								<option value="">Select Payment Mode</option>
								<option value="cash" selected={currentUser?.paymentMode == "cash"}>Cash</option>
								<option value="online" selected={currentUser?.paymentMode == "online"}>Online</option>
							</select>
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Security Amount Received</label></div>
							<select className='col display-flex text-left text-size-12 pd-10 input-box' name="securityAmountStatus" id="securityAmountStatus" required onChange={(e) => inputChangeHandler(e)} selected={currentUser?.securityAmountStatus}>
								<option value="">Select</option>
								<option value="yes" selected={currentUser?.securityAmountStatus == "yes"}>Yes</option>
								<option value="no" selected={currentUser?.securityAmountStatus == "no"}>No</option>
							</select>
						</div>
						<div className='row wd-100 text-center mb-10 mt-20'>
							<div className='labelDiv'><label className='text-left text-size-16'>Security Amount<span className='required'>*</span></label></div>
							<input className='col display-flex text-left text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter Security Amount" name="securityAmount" pattern="[0-9]\d*" maxLength="6" onChange={(e) => inputChangeHandler(e)} />
						</div>
						<div className='row wd-100 text-center mb-10 mt-20'>
							<div className='labelDiv'><label className='text-left text-size-16'>Quantity</label></div>
							<input className='col display-flex text-left text-size-12 ml-10 pd-10 input-box' type="text" placeholder="Enter Quantity" name="quantity" pattern="[0-9]\d*" maxLength="6" onChange={(e) => inputChangeHandler(e)} />
						</div>

						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Tiffin Status</label></div>
							<select className='col display-flex text-left text-size-12 pd-10 input-box' name="tiffinStatus" id="tiffinStatus" onChange={(e) => inputChangeHandler(e)}>
								<option value="">Select Tiffin Delivery Status</option>
								<option value="outForDelivery" selected={currentUser?.tiffinStatus == "outForDelivery"}>Out For Delivery</option>
								<option value="delivered" selected={currentUser?.tiffinStatus == "delivered"}>Delivered</option>
								<option value="notDelivered" selected={currentUser?.tiffinStatus == "notDelivered"}>Not Delivered</option>
								<option value="picked" selected={currentUser?.tiffinStatus == "picked"}>Return Picked</option>
								<option value="notPicked" selected={currentUser?.tiffinStatus == "notPicked"}>Return Not Picked</option>
							</select>
						</div>
						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='labelDiv'><label className='text-left text-size-16'>Product Ordered</label></div>
							<select name="tiffin" id="tiffin" required className="col display-flex text-left text-size-12 pd-10 input-box" onChange={(e) => inputChangeHandler(e)}>
								<option value="">Select Tiffin</option>
								{
									products.map(product => {
										return (<option key={product.key} value={product.key} selected={currentUser?.tiffin == product.key}>{product.value}</option>)
									})
								}
							</select>
						</div>

						<div className='row wd-100 text-center mb-10 mt-10'>
							<div className='buttonDiv'>
								<button type="submit" className='primary-button' name='updateUser'>Update User</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditUser;

